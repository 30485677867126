@charset "UTF-8";
/* Font Import */
@font-face {
	font-family: "Montserrat-Regular";
	src: url("../font/Montserrat-Regular.ttf");
}
@font-face {
	font-family: "Montserrat-Medium";
	src: url("../font/Montserrat-Medium.ttf");
}
@font-face {
	font-family: "Montserrat-MediumItalic";
	src: url("../font/Montserrat-MediumItalic.ttf");
}
@font-face {
	font-family: "Montserrat-Bold";
	src: url("../font/Montserrat-Bold.ttf");
}
@font-face {
	font-family: "Montserrat-SemiBold";
	src: url("../font/Montserrat-SemiBold.ttf");
}

/* Global Reset */
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
li {
	list-style: none;
}
a {
	text-decoration: none;
	color: #777;
}
a:hover {
	text-decoration: none;
}
html,
body {
	width: 100%;
	max-width: 100%;
	height: 100%;
	min-width: 100%;
	min-height: 100%;
	overflow: auto;
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
}
html {
	scroll-behavior: smooth;
}
li {
	list-style: none;
}
.fix-float-left {
	float: left;
}
.not-found-message {
	margin: auto;
}
h3 {
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 52px;	
}

/* Clearfix */
.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}
.clearfix:after {
	clear: both;
}
.clearfix {
	*zoom: 1;
}

/* Style */
/* Header */
.header {
	position: fixed;
	height: 72px;
	width: 100%;
	padding: 0 24px;
	background-color: #071729;
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	font-size: 14px;
	z-index: 101;
}
.header .navbar {
	height: 100%;
	width: 100%;
}
.header .navbar-brand {
	height: 100%;
}
.header img {
	height: 100%;
	width: auto;
}
.navbar-dark .navbar-nav .nav-link {
	color: rgba(255,255,255,.80);
}
.navbar-dark .navbar-nav .nav-link:hover {
	color: rgba(255,255,255,1);
}
.navbar-expand-lg .navbar-nav .nav-link {
	padding-right: 36px;
	padding-left: 36px;
}
.navbar-dark .navbar-nav .nav-link.login-button {
	padding-left: 48px;
	padding-right: 48px;
	color: #333333;
	background-color: #ffffff;
	border-radius: 19px;
}

/* Responsive */
.navbar-expand-lg .navbar-nav .nav-link {
	text-align: center;
}
.navbar-collapse {
	background-color: #071729;
}

/* Main Content */
#root {
	min-height: 100%;
}
.main {
	min-height: 100%;
	min-width: 100%;
	padding-top: 72px;
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
}
.main.has-fullbanner {
	padding-top: 0;
}
.main-content {
	display: flex;
	width: 100%;
}

/* Home */
/* Banner */
.banner {
	display: flex;
	min-height: 100%;
	background-image: url('../img/home_banner.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.banner-content {
	display: flex;
	height: 100%;
}
.banner .left-content, .banner .right-content {
	margin: auto;
	color: #ffffff;
	padding: 0 41px;
}
.left-content {
	order: 1;
}
.right-content {
	order: 2;
}
.banner .register-button {
	display: inline-block;
	background-color: #F6CD2F;
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 18px;
	color: #000000;
	line-height: 36px;
	padding: 0 24px;
	border-radius: 18px;
}
.banner .right-content {
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: rgba(0,0,0,.2);
}
.banner .right-content .text-container {
	margin: auto 0;
}
.banner .right-content > * {
	margin: auto 0;
}
.banner .right-content h1 {
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 52px;
	letter-spacing: 1.5px;
}
.bolded {
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	color: #F6CD2F;
}
.italicized {
	font-family: "Montserrat-MediumItalic", Arial, Helvetica, sans-serif;
	color: #F6CD2F;
}
.banner .right-content h1 strong {
	width: 100%;
	border-top: 6px solid #F6CD2F;
}
.banner .right-content .text {
	padding: 16px 0;
}
.banner .home-link-container a {
	display: inline-block;
	width: 185px;
	height: 185px;
	padding: 20px 0;
	margin: 0 32px 0 0;
	border: 1px solid #ffffff;
	border-radius: 8px;
}
.banner .home-link-container a > p {
	width: 100%;
	margin: 16px 0 0 0;
}
.banner .home-link-container a img {
	width: 50%;
	height: auto;
}
.banner h3 {
	margin: 48px 0;
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 32px;
	line-height: 42px;
}
.banner p {
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	color: #ffffff;
}

/* Home - Mid */
.home-mid {
	padding: 48px 0;
	background-color: #595756;
	color: #ffffff;
}
.home-mid h3 {
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	font-size: 24px;
	line-height: 40px;
	font-weight: normal;
	text-align: center;
}
.home-mid h4 {
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	font-size: 22px;
	font-weight: normal;
	text-align: center;
}
.home-mid p {
	font-family: "Montserrat-Regular", Arial, Helvetica, sans-serif;
	font-size: 14px;
	text-align: center;
}
.home-benefit {
	text-align: center;
}
.home-benefit li {
	float: left;
	padding: 12px;
}
.home-benefit .benefit-container {
	position: relative;
	width: 340px;
	height: 340px;
	padding: 24px;
	border: 1px solid #ffffff;
	border-radius: 8px;
}
.home-benefit img {
	width: 80px;
	height: auto;
	margin: 8px auto;
}
.home-line-container {
	display: block;
	position: absolute;
	height: 3px;
	top: auto;
	bottom: 6px;
	width: 100%;
	left: 0;
	right: 0;
	text-align: center;
}
.home-line {
	display: inline-block;
	width: 33%;
	min-width: 60px;
	border-top: 2px solid #F6CD2F;
}

/* Home - Mid Extra */
.home-mid-extra {
	padding: 82px 0;
	background-color: #F6CD2F;
	color: #000000;
}
.home-mid-extra h3 {
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 32px;
	line-height: 40px;
	font-weight: normal;
	text-align: center;
	margin-bottom: 30px;
}
.home-mid-extra .subtitle {
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 28px;
	text-align: center;
}
.home-mid-extra h4 {
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 16px;
}
.home-user {
	padding: 0;
	margin-top: 80px;
}
.home-user li {
	float: left;
	text-align: center;
}
.home-user .home-user-content {
	width: 240px;
	height: 240px;
	padding: 24px 0;
	border: 1px solid #000000;
	border-radius: 8px;
}
.home-user img {
	width: 50%;
	height: auto;
	margin: 0 0 24px;
}

/* Home Post */
.home-post-list {
	position: relative;
	padding: 48px 0 120px;
	background-color: #ffffff;
}
.home-post-list > h3 {
	display: inline-block;
	padding: 0 32px;
	margin-bottom: 48px;
	background-color: #000000;
	font-family: "Montserrat-SemiBold", Arial, Helvetica, sans-serif;
	font-size: 32px;
	line-height: 48px;
	color: #ffffff;
	border-radius: 0 24px 0 0;
}
.home-post-container {
	padding: 16px;
	float: left;
}
.home-post {
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 18px;
	overflow: hidden;
	display: flex;
}
.home-post > div {
	float: left;
	margin: auto;
}
.home-post img {
	width: 100%;
	height: auto;
}
.home-post-content {
	padding: 16px;
}
.home-post-content > div {
	margin: auto 0;
}
.home-post p {
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	font-size: 14px;
	margin-bottom: 0;
}
.home-post a {
	display: inline-block;
	padding: 0 12px;
	border-radius: 14px;
	color: #333333;
	font-size: 12px;
	background-color: #F6CD2F;
}
.slider-nav {
	position: absolute;
	top: 200px;
}
.nav-prev {
	left: 12px;
	right: auto;
}
.nav-next {
	left: auto;
	right:  12px;
}
.slider-nav img {
	width: 60px;
}
.post-container {
	width: 100%;
	overflow: hidden;
}
.home-post-container {
	float: left;
}

/* Prosedur */
.prosedur-banner {
	height: 400px;
	background-image: url('../img/prosedur.jpg');
	background-position: right center;
	background-size: cover;
	background-repeat: no-repeat;
}
.prosedur-banner h3 {
}
.prosedur-mid {
	padding: 48px 0;
}
.prosedur-mid h3 {
	margin-bottom: 48px;
	font-size: 32px;
}
.accordion-item {
	border: none;
	margin-bottom: 24px;
}
.accordion-button {	
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	font-size: 18px;
	background-color: #e6e6e5;
}
.accordion-button:not(.collapsed) {
	background-color: #0b1829;
	color: #ffffff;
}
.accordion-button::before {
	width: 20px;
	height: 20px;
	min-width: 20px;
	min-height: 20px;
	border-radius: 10px;
	content: "";
	margin-right: 24px;
	background-color: #ffffff;
	background-repeat: no-repeat;
	background-size: 16px;
	background-position: center;
	transform: rotate(-90deg);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button:not(.collapsed)::before {
	transform: rotate(-360deg);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23071729'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button::after {
	background-image: none;
}
.accordion-button:not(.collapsed)::after {
	background-image: none;
}
.accordion-item .accordion-button {
	border-radius: 0 !important;
}
.accordion-body {
	padding: 1rem 1.25rem 0 1.25rem;
}
.accordion-item ul {
	margin-bottom: 0;
}
.accordion-item li {
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	font-size: 18px;
	line-height: 32px;
	list-style: disc;
	color: #423D3D;
}
.accordion-item li a {
	color: #423D3D;
}
.accordion-item li:hover {
	color: #0067d8;
}
.accordion-item li:hover a {
	color: #0067d8;
}
.prosedur-register {
	padding: 82px 0;
	background-color: #f7ce2f;
	text-align: center;
}
.prosedur-register h3 {
	margin-bottom: 24px;
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 32px;
	text-align: center;
}
.prosedur-register a {
	padding: 10px 48px;
	background-color: #f3f3f4;
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 18px;
	color: #000000;
	border-radius: 24px;
	border: 1px solid #000000;
	box-shadow: 0 10px 5px rgba(147, 108, 0, .15);
	margin-bottom: 0;
}
.center-button {
	margin: 32px 0;
}
.prosedur-dokumen {
	padding: 82px 0;
}
.prosedur-dokumen h3, .prosedur-dokumen p {
	text-align: center;
}
.prosedur-dokumen h3 {
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 32px;
}
.prosedur-dokumen p {
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 18px;
}
.prosedur-dokumen .prosedur-dokumen-list {
	padding: 0;
	margin: 32px 0;
}
.prosedur-dokumen .prosedur-dokumen-item {
	float: left;
	padding: 12px;
}
.prosedur-dokumen-item h4 {
	text-align: center;
}
.prosedur-dokumen-item h4 strong {
	font-weight: normal;
	font-size: 20px;	
	border-bottom: 2px solid #F6CD2F;
}
.dokumen-list-container {
	position: relative;
	height: 540px;
	box-shadow: 0 15px 10px rgba(0, 0, 0, .1);
}
.dokumen-list-container h4 {
	padding: 12px 0;
	font-family: "Montserrat-SemiBold", Arial, Helvetica, sans-serif;
	font-size: 24px;
	color: #ffffff;
}
.prosedur-dokumen ol {
	padding: 0 12px 24px 36px;
	margin: 24px 0;
}
.prosedur-dokumen ol li {
	list-style-type:decimal;
}
.prosedur-button-container {	
	display: block;
	position: absolute;
	top: auto;
	bottom: 6px;
	width: 100%;
	left: 0;
	right: 0;
}
.prosedur-dokumen .prosedur-button {
	display: inline-block;
	padding: 2px 24px;
	font-family: "Montserrat-MediumItalic", Arial, Helvetica, sans-serif;
	font-size: 12px;
	color: #ffffff;
	background-color: #0067d8;
	border-radius: 11px;
	box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
}
.prosedur-detail-button:hover {
	color: #ffffff;
}

/* Landasan Hukum */
.landasan-banner {
	height: 400px;
	background-image: url('../img/landasan.jpg');
	background-position: right center;
	background-size: cover;
	background-repeat: no-repeat;
}
.landasan-banner h3 {
	color: #ffffff;
}
.landasan-sub-banner {
	background-color: #F6CD2F;
	padding: 24px 0;
}
.landasan-sub-banner img {
	width: 100%;
	height: auto;
}
.landasan-sub-banner .container {
	display: flex;
}
.landasan-sub-banner .container > * {
	margin: auto;
}
.landasan-sub-banner h4 {
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 32px;
}
.landasan-sub-banner p {
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	font-size: 18px;
	line-height: 28px;
}
.landasan-content {
	padding: 60px 0;
}
.landasan-content h4 {
	font-family: "Montserrat-SemiBold", Arial, Helvetica, sans-serif;
	font-size: 24px;
	margin-bottom: 48px;
}
.landasan-content ol li {
	padding: 12px;
	list-style-type:decimal;
	font-size: 14px;
	line-height: 28px;
	margin-bottom: 12px;
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	font-size: 18px;
	line-height: 28px;
	border-radius: 4px;
	background-color: #f5f7fc;
}
.landasan-content li a {
	display: inline-block;
	padding: 0 12px;
	border-radius: 14px;
	color: #ffffff;
	background-color: #3778BD;
	font-family: "Montserrat-MediumItalic", Arial, Helvetica, sans-serif;
	font-size: 12px;
	line-height: 22px;
}

/* Prosedur Register */
.prosedur-register-banner {
	padding: 80px;
	text-align: center;
}
.prosedur-register-banner h3 {
	font-size: 32px;
	line-height: 36px;
}
.prosedur-register-banner p {
	margin-top: 32px;
}
.prosedur-register-main img {
	width: 100%;
	height: auto;
}
.ptb-64px {
	padding: 64px 0;
}
.filenote {
	margin-top: 12px;
}

/* Privasi */
.privasi-banner {
	height: 280px;
	background-image: url('../img/kebijakan_privasi.jpg');
	background-position: right center;
	background-size: cover;
	background-repeat: no-repeat;
}
.privasi-banner h3 {
	color: #ffffff;
	text-shadow: 1px 1px 6px #333333;
}
.privasi-content {
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	padding: 48px 0;
}
.privasi-content h4 {
	font-size: 20px;
}
.privasi-content p {
	font-size: 16px;
	margin-bottom: 24px;
}
.privasi-content p.text-title {
	margin-bottom: 6px;
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
}
.privasi-content ol {
	margin-bottom: 24px;
}
.privasi-content ol li {
	list-style: decimal;
}

/* Syarat & Ketentuan */
.privasi-banner {
	height: 280px;
	background-image: url('../img/syarat_ketentuan_banner.jpg');
	background-position: right center;
	background-size: cover;
	background-repeat: no-repeat;
}

/* FAQ */
.faq-banner {
	height: 280px;
	background-image: url('../img/faq.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.faq-list p {
	margin-bottom: 0;
}
.faq-list li {
	list-style: decimal;
}
.faq-list .accordion-body > p {
	margin-bottom: 12px;
}
.faq-list .accordion-body > ol > li {
	margin-bottom: 12px;
}
.faq-list li:hover {
	color: #423D3D;
	cursor: default;
}
.faq-list li li {
	list-style:lower-alpha;
}

/* Prosedur Detail */
.prosedur-detail-main {
	padding: 0 0 80px;
}
.prosedur-detail-main img {
	width: 100%;
	height: auto;
}

/* Kontak */
.contact-list {
	padding: 0;
}
.contact-list li {
	border-top: 1px solid #cccccc;	
}
.contact-list li:first-child {
	border-top: none;
}
.contact-detail {
	padding: 16px 0;
}
.contact-detail h5 {
	color: #061729;
}
.contact-detail p {
	margin-bottom: 0;
}
.contact-detail a {
	/*display: inline-block;
	padding: 2px 12px;
	border-radius: 14px;
	background-color: #F6CD2F;
	color: #ffffff;*/
	color: #0a58ca;
}

/* Footer */
.footer {
	width: 100%;
	position: relative;
	font-family: "Montserrat-Medium", Arial, Helvetica, sans-serif;
	font-size: 14px;
}
.footer .container > * {
	display: inline-block;
}
.footer .top {
	padding: 40px 0;
	border-top: 2px solid #bcbcbc;
}
.footer .top .container {
	display: flex;
}
.footer .top .container > * {
	margin: auto 0;
}
.footer-nav {
	padding: 0;
	margin: 0;
}
.footer-nav li {
	line-height: 28px;
}
.footer-nav li a {
	color: #818181;	
}
.footer h4 {
	font-family: "Montserrat-Bold", Arial, Helvetica, sans-serif;
	font-size: 28px;
	text-align: center;
}
.store-link {
	padding: 0;
	text-align: center;
}
.store-link li {
	display: inline-block;
	padding: 0 8px;
}
.footer .bottom {
	padding: 32px 0;
	background-color: #071729;	
}
.footer p {
	margin: 0;
	color: #ffffff;
}

@media (max-width: 1200px) {

}
@media (max-width: 992px) {
	.home-link-container {
		text-align: center;
	}
	.banner .home-link-container a {
		margin: 0 auto 24px;
	}
	.home-benefit {
		padding: 0;
	}
	.home-benefit .benefit-container {
		width: 100%;
		height: 400px;
	}

	.home-user .home-user-content {
		width: 80%;
	}
}
@media (max-width: 768px) {
	.home-mid br {
		display: none;
	}
	.home-benefit .benefit-container {
		width: 100%;
		height: auto;
	}
	.home-mid-extra .subtitle {
		padding: 0 24px;
	}
	.home-user-content {
		margin: 12px auto;
	}
	.landasan-content h4 {
		padding: 0 24px;
	}
}
@media (max-width: 576px) {

	.banner {
		display: block;
		padding-top: 100px;
		padding-bottom: 16px;
	}

	.banner-content {
		display: block;
	}
	.banner .home-link-container {
		margin-top: 48px;
	}
	.banner .home-link-container a {
		margin: 0 auto 24px;
	}
	.banner .left-content {
		text-align: center;
	}

	.home-benefit {
		padding: 0;
	}	
	.home-user li {
		float: none;
	}

	.prosedur-banner {
		height: auto;
		padding: 40px 0 0;
		background-image: none;
	}
	.prosedur-banner h3 {
		text-align: center;
		font-size: 32px;
	}
	.prosedur-mid h3 {
		display: none;
	}

	.landasan-banner {
		height: auto;
		padding: 40px 0;
	}
	.landasan-banner h3 {
		font-size: 32px;
		text-align: center;
	}

	.privasi-banner {
		font-size: 32px;
	}

	.faq-banner {
		height: 150px;
	}

	.footer .top .container {
		display: block;
	}
	.footer .top .container > .store-link-container {
		margin-top: 24px;
		width: 100%;
	}

	.footer ul {
		width: 100%;
	}
	.footer li {
		width: 100%;
		margin-right: 0;
		padding-right: 0;
		text-align: center;
	}
	.footer p {
		width: 100%;
		margin-top: 24px;
		text-align: center;
	}
}